.postContent {
  margin: 0 auto;
  max-width: 750px;
}

.inputInline {
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  color: #333;
  margin-bottom: 20px;
  margin-top: 30px;
  overflow: hidden;
  padding: 0;
  resize: none;
  text-align: center;
  transition: none;
  width: 100%;
  word-wrap: break-word;
}

.inputInline:focus {
  outline: none;
}

.title {
  font-size: 28px;
  font-weight: 600;
}

.subtitle {
  font-size: 18px;
  margin-top: 5px;
}

.summary {
  font-size: 18px;
  margin-top: 0;
}

.mustache {
  font-size: 18px;
  margin-top: 0;
}

.image-selected {
  position: relative;
}

.image-selected img {
  box-shadow: 0 0 0 2px #d52c82, 0 4px 6px #ccced0;
}

.image-selected::after {
  position: absolute;
  right: 5px;
  bottom: 10px;
  z-index: 10;
  width: 28px;
  height: 28px;
  padding: 0;
  transition: color 90ms ease;
  box-shadow: 0 0 8px rgba(61,65,69,.4);
  background: #d52c82;
  border-radius: 50%;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: #fff;
}

.images-selected {
  position: relative;
}

.images-selected img {
  box-shadow: 0 0 0 2px #d52c82, 0 4px 6px #ccced0;
}

.images-selected::after {
  content: attr(selectedNumber) "";
  position: absolute;
  right: 5px;
  bottom: 10px;
  z-index: 10;
  width: 28px;
  height: 28px;
  padding: 0;
  transition: color 90ms ease;
  box-shadow: 0 0 8px rgba(61,65,69,.4);
  background: #d52c82;
  border-radius: 50%;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: #fff;
}
